import React from 'react'
import { Dialog, IconButton } from '@mui/material'
import { IoClose } from 'react-icons/io5'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean
  showClose?: boolean
  paperClass?: string
  fullWidth?: boolean
  isRed?: boolean
  onClose?: () => void
  onCloseButtonClick?: () => void
}

const CustomDialog = (props: Props) => {
  const {
    open,
    fullWidth = false,
    showClose = true,
    isRed = false,
    paperClass = '',
    children,
    onClose,
    onCloseButtonClick,
  } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      classes={{
        container: '!max-w-[100vw]',
        paper: `relative !min-w-[50vw] pt-4 ${paperClass} ${isRed ? '!bg-red !text-white' : ''}`,
      }}
    >
      {showClose && (
        <IconButton
          classes={{ root: `!absolute ` }}
          sx={{
            right: 0,
            top: 0,
          }}
          onClick={() => onCloseButtonClick?.()}
        >
          <IoClose className={isRed ? '!text-white' : ''} />
        </IconButton>
      )}
      {children}
    </Dialog>
  )
}

export default CustomDialog
