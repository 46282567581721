import { chainId } from '@/config/wagmi'
import { nftAddress } from '@/contract/ca'
import { getActiveSign } from '@/utils/sign'

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  Voice = 'audio/webm',
  EventStream = 'text/event-stream',
}

const addUrl = ['GET', 'HEAD']

class Fetch {
  private baseURL: string
  private requiredAuth: boolean
  private isStream: boolean
  private chatAuth?: object

  /**
   * @param baseURL 基础URL
   * @param requiredAuth 是否需要 sign 签名认证
   * @param isStream 是否为流式返回
   */
  constructor(baseURL: string, requiredAuth = true, isStream = false, chatAuth?: object) {
    this.baseURL = baseURL
    this.requiredAuth = requiredAuth
    this.isStream = isStream
    this.chatAuth = chatAuth
  }

  private async handleStatus(data: any) {
    // 441 代表倒计时，所以不能把它reject
    if (!data?.status || (data.status > 200 && data.status != 441)) {
      return Promise.reject(`code ${data.status}: ${data.message}`)
    }
  }

  private async fetchRequest<T>(
    url: string,
    options: Record<string, any>,
    extractData = true
  ): Promise<T> {
    const params = {
      sign: getActiveSign(),
      chainid: `${chainId}`,
    }

    if (this.chatAuth != undefined) {
      Object.assign(params, this.chatAuth)
    }

    if (addUrl.includes(options.method)) {
      const queryParams = new URLSearchParams(params).toString()
      url += this.requiredAuth ? `&${queryParams}` : ''
    } else {
      if (options.headers['Content-Type'] != ContentType.FormData) {
        // 请求体sign
        // @ts-ignore
        options.body = JSON.stringify({
          ...options.body,
          ...(this.requiredAuth ? params : {}),
        })
      }
    }

    const response = await fetch(url, options)

    if (!response.ok) {
      console.error('[Request Error]', url)
      const error = await response.json()

      throw error as T
    }

    if (response.status != 200) {
      console.error(`错误状态码: ${response.status}`)
    }

    if (this.isStream) {
      return response.body as T
    }

    const data = await response.json()
    if (extractData) {
      await this.handleStatus(data)
    }

    // 音频的返回不是 `{ data: xxx }` 格式, 无法 `data.data`
    return (extractData ? data.data : data) as T
  }

  public async get<T>(
    url: string,
    params: Record<string, any> = {},
    extractData = true
  ): Promise<T> {
    for (const key in params) {
      if (params[key] === null || params[key] === undefined) {
        delete params[key]
      }
    }
    const queryParams = new URLSearchParams(params).toString()
    const fullUrl = `${this.baseURL}${url}?${queryParams}`
    const options: RequestInit = {
      method: 'GET',
    }
    return this.fetchRequest<T>(fullUrl, options, extractData)
  }

  public async patch<T>(
    url: string,
    params: Record<string, any> = {},
    type = ContentType.Json
  ): Promise<T> {
    return this.post(url, params, type, true, undefined, false, 'PATCH')
  }

  public async post<T>(
    url: string,
    params: Record<string, any> = {},
    type = ContentType.Json,
    extractData = true,
    chatAuth?: object | boolean, // 如果是布尔的话，则会修改 `requiredAuth`
    isStream?: boolean,
    method = 'POST'
  ): Promise<T> {
    const fullUrl = `${this.baseURL}${url}`
    let body

    if (typeof chatAuth === 'boolean') {
      this.requiredAuth = chatAuth
    } else if (chatAuth) {
      Object.assign(this.chatAuth || {}, chatAuth)
    }

    if (isStream !== undefined) {
      this.isStream = isStream
    }

    if (type === ContentType.Json) {
      body = params
    } else if (type === ContentType.FormData) {
      // 上传图像
      const data = new FormData()
      data.append('file', params as File)
      body = data
    } else {
      // 兼容音频
      body = params as BodyInit
    }

    const options = {
      method,
      body,
      headers: {
        'Content-Type': type,
      },
    }

    return this.fetchRequest<T>(fullUrl, options, extractData)
  }

  public async put<T>(
    url: string,
    params: Record<string, any> = {},
    extractData = true
  ): Promise<T> {
    const fullUrl = `${this.baseURL}${url}`
    const options = {
      method: 'PUT',
      body: params,
      headers: {
        'Content-Type': ContentType.Json,
      },
    }
    return this.fetchRequest<T>(fullUrl, options, extractData)
  }

  public async del<T>(
    url: string,
    params: Record<string, any> = {},
    extractData = true
  ): Promise<T> {
    const fullUrl = `${this.baseURL}${url}`

    const options = {
      method: 'DELETE',
      body: params,
      headers: {
        'Content-Type': ContentType.Json,
      },
    }
    return this.fetchRequest<T>(fullUrl, options, extractData)
  }
}

// export const api = new Fetch('https://tapi.nobody.am/')
export const api = new Fetch('https://api.nobody.am/')

export const voiceApi = new Fetch('https://voice.satoshi-gpt.com', false)

export const chatApi = new Fetch('https://ai.nobody.am', true, true, {
  contract: nftAddress,
})

export const momentApi = api
