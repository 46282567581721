import { useMutation, useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useAccount, useReadContract } from 'wagmi'

import { nftConfig } from './../contract/nft'
import { NftApi } from '@/api/nft/nft'
import { UpdateNftInfoReq } from '@/api/nft/nft-type'
import { useGlobalStore } from '@/store/global'

export const useNftInfo = (overrideId?: number | string) => {
  const { currentNftId, setCurrentNft } = useGlobalStore()
  const { address } = useAccount()
  const { query } = useRouter()

  const { data: nftBalance } = useReadContract({
    ...nftConfig,
    functionName: 'balanceOf',
    args: [address!],
  })

  // 获取我的 NFT 列表
  const {
    data: myNftList,
    isLoading,
    refetch: refetchNftList,
  } = useQuery({
    queryKey: [NftApi.getMyNftList.name],
    queryFn: NftApi.getMyNftList,
    refetchInterval: 5_000,
  })

  const firstNft = myNftList?.list[0]

  const isMyNft = myNftList?.list.map((n) => n.token_id).includes(Number(query.id) || 0)

  if (firstNft && currentNftId == -1) {
    queueMicrotask(() => {
      setCurrentNft(firstNft)
    })
  }

  // 获取 NFT 详情
  const {
    data: nftInfo,
    isRefetching: isRefetchingNftInfo,
    refetch: refetchNftInfo,
  } = useQuery({
    queryKey: [NftApi.getNftInfo.name, currentNftId, overrideId],
    queryFn: () => {
      if (overrideId === -1 && currentNftId === -1) return null
      const token_id = Number(overrideId ?? currentNftId)

      return NftApi.getNftInfo({ token_id })
    },
    refetchInterval: 5_000,
    refetchOnMount: true,
    refetchOnReconnect: true,
  })

  // 修改 NFT 信息
  const { mutateAsync: updateNftInfo, isPending: isUpdating } = useMutation({
    mutationKey: [NftApi.updateNftInfo.name],
    mutationFn: (params: UpdateNftInfoReq) => NftApi.updateNftInfo(params),
  })

  const refetchAll = async () => Promise.all([refetchNftList(), refetchNftInfo()])

  return {
    isPending: isLoading,
    myNftList,
    nftInfo,
    nftBalance,
    isUpdating,
    isRefetchingNftInfo,
    isMyNft,
    updateNftInfo,
    refetchNftList,
    refetchNftInfo,
    refetchAll,
  }
}
