import { api } from '../fetch'
import { Pagination } from '../user/user-type'

import type {
  GetAllNftsRes,
  NftInfoMineRes,
  NftInfoReq,
  NftInfoRes,
  NftInfoUpgradeRes,
  NftUpgradeReq,
  NftUpgradeRes,
  UpdateNftInfoReq,
  UploadNftHashRes,
} from './nft-type'

export class NftApi {
  /**
   * 获取 NFT 详情
   */
  static getNftInfo(query?: NftInfoReq) {
    return api.get<NftInfoRes>('nft', query)
  }

  /**
   * 更新 NFT 信息
   */
  static updateNftInfo(query?: UpdateNftInfoReq) {
    return api.put<null>('nft', query)
  }

  /**
   * 获取我的 NFT 列表
   */
  static getMyNftList() {
    return api.get<NftInfoMineRes>('nft/mine')
  }

  /**
   * 获取升级 NFT 所需材料
   * @grade 当前等级 number
   */
  static getNftUpgrade(grade: number) {
    return api.get<NftInfoUpgradeRes>('nft/upgrade', { grade })
  }

  /**
   * 升级 NFT
   **/
  static upgradeNftUpgrade(params: NftUpgradeReq) {
    return api.post<NftUpgradeRes>('nft/upgrade', params)
  }

  /**
   * 提交铸造 NFT 的 hash
   **/
  static uploadNftHash(hash: string) {
    return api.post<UploadNftHashRes>('user/mint', { hash })
  }

  /**
   * 提交铸造 NFT 的 hash
   **/
  static getAllNfts(params: Pagination) {
    return api.get<GetAllNftsRes>('nft/list', params)
  }
}
